import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KontrakService {
  baseUrl = 'https://aset-ka.starblet.com';
  constructor(private http: HttpClient) { }

  getKontrak() {
    const url = this.baseUrl+"/perencanaan/bystatus/disetujui";
    return this.http.get(url).pipe(map((res: any) => {
      console.log('res', res);
      return res;
    }))
  }

  getAset(id) {
    const url = this.baseUrl+"/detailrencana/show/"+id;
    return this.http.get(url).pipe(map((res: any) => {
      console.log('res', res);
      return res;
    }))
  }

  uploadPic(id, imgbase64) {
    let postData = {
            "image_aset": imgbase64
    }

    this.http.post("http://localhost:8080/Detailrencana/uploadimg/"+id, postData)
      .subscribe(data => {
        console.log(data['_body']);
       }, error => {
        console.log(error);
      });
  }
  
}
