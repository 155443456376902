import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KontrakService } from './../services/kontrak.service';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';

@Component({
  selector: 'app-aset-list',
  templateUrl: './aset-list.page.html',
  styleUrls: ['./aset-list.page.scss'],
})
export class AsetListPage implements OnInit {
  id: any;
  items: any;
  constructor(private route: ActivatedRoute, private router: Router, private kontrakService: KontrakService, private camera: Camera) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('item');
    console.log(this.id);
    this.kontrakService.getAset(this.id).subscribe(res =>  {
      this.items = res;
      console.log(this.items);
    });
  }

  takePic(id) {
    const options: CameraOptions = {
      quality: 60,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE
    }

    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      let base64Image = 'data:image/jpeg;base64,' + imageData;
     }, (err) => {
      // Handle error
     });
  }

}
